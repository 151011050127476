import styled from "styled-components"
import { Style } from "../../style"

interface SliceProps {
  number: number
  length: number
}

const Slice = styled.div<SliceProps>`
  margin-left: auto;
  margin-right: auto;
  max-width: ${Style.layout.maxWidth.contentWrapper}px;
  margin-bottom: ${Style.megaClamp(4, 8)};

  ${props =>
    props.number === props.length
      ? `
    margin-bottom: 0;
  `
      : ""}
`

const Title = styled.a`
  color: ${Style.color.brand};
  text-decoration: none;
  display: block;
  margin-bottom: ${Style.megaClamp(
    0.5,
    1,
    Style.layout.maxWidth.contentWrapper
  )};
  font-size: ${Style.megaClamp(2, 2.5)};
  line-height: ${Style.megaClamp(3, 4)};
  font-weight: ${Style.font.weight.normal};
  display: inline-block;

  ${Style.focusAccessible()};
`

const Category = styled.p`
  margin-bottom: ${Style.megaClamp(1, 2)} !important;
  font-size: ${Style.megaClamp(1.5, 2)} !important;
  line-height: ${Style.megaClamp(2.5, 3)} !important;
  font-weight: ${Style.font.weight.normal};
`

const Excerpt = styled.div`
  p {
    color: ${Style.color.foreground};
    font-weight: ${Style.font.weight.normal};
    font-size: ${Style.megaClamp(2, 2.5)};
    line-height: ${Style.megaClamp(3, 4)};
  }

  a {
    color: ${Style.color.brand};
    text-decoration: none;

    ${Style.focusAccessible()};
  }
`

export { Slice, Title, Category, Excerpt }
