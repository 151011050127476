import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Meta/seo"
import { ContentWrapper } from "../components/ContentWrapper"
import { DesignSliceList } from "../components/DesignSliceList"
import { DesignSlice } from "../data/types/interfaces"

interface Props {
  data: {
    markdownRemark: {
      html: string
    }
    allDesignSlicesYaml: {
      nodes: DesignSlice[]
    }
  }
}

const DesignPage: React.FC<Props> = ({ data }) => {
  return (
    <Layout headerBorderBottom={true}>
      <SEO
        title="Design"
        description="I've worked with a wealth of clients over the last decade, designing everything from record sleeves to multi-channel campaigns."
      />
      <ContentWrapper>
        <DesignSliceList data={data.allDesignSlicesYaml} />
        <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </ContentWrapper>
    </Layout>
  )
}

export default DesignPage

export const query = graphql`
  query DesignQuery {
    markdownRemark(frontmatter: { slug: { eq: "design" } }) {
      html
    }
    allDesignSlicesYaml(sort: { fields: order }) {
      nodes {
        id
        category
        content
        title
        url
      }
    }
  }
`
