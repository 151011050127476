import React from "react"
import ReactMarkdown from "react-markdown"
import { DesignSlice } from "../../data/types/interfaces"

import { Title, Category, Excerpt, Slice } from "./index.style"

interface Props {
  data: {
    nodes: DesignSlice[]
  }
}

const DesignSliceList: React.FC<Props> = ({ data }) => {
  const makeDesignSlices = () => {
    return data.nodes.map((slice, index) => {
      return (
        <Slice key={slice.id} number={index + 1} length={data.nodes.length}>
          <Title href={slice.url}>{slice.title}</Title>
          <Category>{slice.category}</Category>
          <Excerpt>
            <ReactMarkdown source={slice.content} />
          </Excerpt>
        </Slice>
      )
    })
  }

  return <>{makeDesignSlices()}</>
}

export { DesignSliceList }
